import { Container, Row, Col } from "react-bootstrap";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";


function Experience() {
  
 
  return (
    <div>
      <Container fluid className="resume-section">
      

<Container>
        <Row>
        <h1 class="vw-page-title">Experience</h1>
   
        </Row>

        <Row>

        <Col xs={12} md={12}>
<p>I am currently a Sofware Engineer at Heartland Business Systems. I develop and maintain websites and custom apps for customers all over the world. I primarily work with the .net stack but do have some experience with php. I work with a variety of different CMS tools including; Kentico and WordPress. For a full listing of my experience, please find me on LinkedIn.</p>
      </Col>
    
        
        
        </Row>
</Container>
       








<hr/>



      
      </Container>
    </div>
  );
}

export default Experience;
